import { PublicClientApplication, type AccountInfo, type RedirectRequest } from "@azure/msal-browser";
import { reactive } from "vue";

export const msalConfig = {
  auth: {
    clientId: process.env.VITE_CLIENTID!,
    authority: process.env.VITE_AUTHORITY,
    redirectUri: `https://${window.location.host}`,
    postLogoutUri: `https://${window.location.host}`,
  },
  cache: {
    cacheStorage: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const graphScopes: RedirectRequest = {
  scopes: ["user.read", "openid", "profile"],
};

export const loginState = reactive({
  isAuthenticated: false,
  // token: null as string | null,
  account: null as AccountInfo | null,
});

export const samlInstance = new PublicClientApplication(msalConfig);
